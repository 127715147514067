<template>
  <div ref="container" class="position-relative">
    <div class="w-100 multiFilter px-2">
       <div
      class="quizell-tags d-flex flex-wrap"
      v-if="singleSelect && selectedText">
      <div
        class="tag px-1 m-1 d-flex align-items-center justify-content-between">
        {{ selectedText }}
        <i
          class="fa fa-times pl-2 cross-icon"
          @click="selectedText = null"
          aria-hidden="true"></i>
      </div>
    </div>
      <div class="d-flex align-items-center justify-content-between">
      <input
        v-model="searchText"
        ref="searchInput"
        class="filterSearch w-100 "
        type="text"
        @focus="onSearchboxFocus"
        @blur="onSearchboxBlur"
        :disabled="isLoading" />
      <b-spinner v-if="isLoading" small style="color: #4d1b7e"></b-spinner>
      </div>
     
    </div>
    <ul class="popover-list" v-if="(!singleSelect) && listActive">
      <li
        class="popover-item cursor-pointer"
        v-if="searchText && addInList"
        @click="
          () => {
           
            if(!selected.some(item=>item.title==searchText))
            {
              selected.push({ title: searchText });
             $emit('add',{ title: searchText });
             searchText=''
            }
            listActive = false;
          }
        ">
        <div class="d-flex align-items-center">
          <i
            class="fa fa-plus-circle"
            style="color: rgb(183, 181, 181)"
            aria-hidden="true"></i>
          <div class="span mx-2 font-weight-bold">Add</div>
          "{{ searchText }}"
        </div>
      </li>
      <template v-if="filteredItems.length > 0">
        <li class="popover-item" v-for="item in filteredItems" :key="item.id">
          <b-form-checkbox v-model="selected" :value="item" class="checkbox cursor-pointer">
            {{ item.title }}</b-form-checkbox
          >
        </li>
      </template>
      <template v-else-if="items.length <= 0">
        <li  class="popover-item">The item list remained empty.</li>
      </template>
      <li  class="popover-item" v-else-if="items.length > 0 && filteredItems.length <= 0">
        This item does not exist yet.
      </li>
    </ul>
    <!-- For Single Select -->
    <ul class="popover-list cursor-pointer" v-else-if="listActive">
      <li  style=""
        class="popover-item"
        v-if="searchText && addInList"
        @click="
          () => {
            $emit('add',{ title: searchText });
            selectedText= searchText
            listActive = false;
            searchText='';
          }
        ">
        <div class="d-flex align-items-center">
          <i
            class="fa fa-plus-circle"
            style="color: rgb(183, 181, 181)"
            aria-hidden="true"></i>
          <div class="span mx-2 font-weight-bold">Add</div>
          "{{ searchText }}"
        </div>
      </li>
      <template v-if="filteredItems.length > 0">
        <li
          class="popover-item"
          v-for="(item, index) in filteredItems"
          :key="index">
          <b-form-radio
            name="all"
            v-model="selectedText"
            class="radio-button cursor-pointer"
            :value="item.title"
            @input="()=>{
              $emit('input',selectedText)
              listActive = false
              }"
            >{{ item.title }}</b-form-radio
          >
        </li>
      </template>
      <template v-else-if="items.length <= 0">
        <li class="popover-item">The item list remained empty.</li>
      </template>
      <li v-else-if="items.length > 0 && filteredItems.length <= 0" class="popover-item">
        This item does not exist yet.
      </li>
    </ul>

    
    <div class="quizell-tags d-flex flex-wrap" v-if="!singleSelect">
      <div
        class="tag px-1 m-1  d-flex align-items-center justify-content-between"
        v-for="(item, index) in selected"
        :key="index">
        <template v-if="item.title">
        
          <span style="    width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;">  {{ item.title }}</span>
          <i
            class="fa fa-times pl-2 cross-icon"
            @click="remove(index)"
            aria-hidden="true"></i>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  errorCaptured: function (err) {
    console.log("Caught error", err.message);

    return false;
  },
  watch: {
    selected: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
  },
  props: {
    isLoading: Boolean,
    items: Array,
    selectedItems: {
      type:Array,
      default:()=>[]
    },
    addInList: Boolean,
    selectedSingle: {
      type:String,
      default:''
    },
    singleSelect: Boolean,
  },
  data() {
    const selectedArray=this.selectedItems?[...this.selectedItems]:[]
    return {
      selected: selectedArray,
      searchText: "",
      listActive: false,
      selectedText:this.selectedSingle? this.selectedSingle.substr(0):'',
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) =>
        item.title.toLowerCase().includes(this.searchText.toLowerCase())
      );
    },
  },
  methods: {
    onSearchboxFocus() {
      this.listActive = true;
      document.addEventListener("click", this.onDocumentClick);
    },
    onSearchboxBlur() {
      // If you want to close the list when the searchbox loses focus, uncomment the next line
      // this.listActive = false;
    },
    onDocumentClick(event) {
      if (this.$refs.container)
        if (!this.$refs.container.contains(event.target)) {
          this.listActive = false;
          // this.$refs.searchInput.blur();
        }
    },
    remove(index) {
      this.selected.splice(index, 1);
    },
  },
  beforeUnmount() {
    document.removeEventListener("click", this.onDocumentClick);
  },
};
</script>

<style scoped>
.cursor-pointer{
  cursor:pointer!important
}
/* Add your custom CSS styling here */
.popover-list {
  list-style-type: none;
  padding: 0;

  position: absolute;
  z-index: 11111111111;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  max-height: 200px;
  min-height: 50px;
  overflow-y: scroll;
}

.popover-item {
  padding: 8px;
 
}

.popover-item:hover {
  background-color: #f0f0f0;
}

.filterSearch {
  padding: 8px;
  /* border: 1px solid #ccc; */
  border: none;
  border-radius: 4px;
  margin-bottom: 8px;
  margin: 0px;
}

button {
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.filterSearch:focus-visible {
  border: none;
  outline: none;
}
.multiFilter {
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  background: white;
  transition: 0.3s ease all;
 
  width: 100%;
}
.multiFilter:focus-within {
  color: #495057;
  background-color: #fff;
  /* border-color: #4d1b7e; */
  outline: none;
  box-shadow: 0 0 0 0.1rem rgba(77, 27, 126, 0.25);
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--secondary-color);
  background-color: var(--secondary-color) !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--secondary-color);
}
.tag {
  background: #e3e3e3;
  border-radius: 0.5rem;
  font-size: 14px;
  padding: 0px 10px !important;
  max-width: 100%;
}
.cross-icon {
  color: #8a8a8a;
}
.cross-icon:hover {
  color: black;
  cursor: pointer;
}
.tag:hover {
  background: #d4d3d3;
  border-radius: 0.5rem;
  font-size: 14px;
  padding: 0px 10px !important;
}
.radio-button
  >>> .custom-control-input:checked
  ~ .custom-control-label::before {
  background: var(--secondary-color);
  border-color: var(--secondary-color);
}
.radio-button >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--secondary-color);
}
</style>