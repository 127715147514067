<template>
     <div name="fade">
      <delete-modal id="leadsBulkDelete" @click="$emit('delete')" ref="leadsBulkDelete" :isLoading="isLoadingDelete" :title="`Delete ${name}`" :item="name"></delete-modal>
      <div class="selectedItemsDev" >
        <div class="">
          <div class="InnerDiv" :class="[isDelete?'justify-content-between':'justify-content-end']">
            <button v-if="isDelete" @click="$refs.leadsBulkDelete.open()" :disabled="isLoadingDelete" class="deleteProduct align-items-center">
                <!--  -->
                <span v-if="!isLoadingDelete">
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.5 7.64706C18.5 7.23285 18.1642 6.89706 17.75 6.89706C17.3358 6.89706 17 7.23285 17 7.64706H18.5ZM8 7.64706C8 7.23285 7.66421 6.89706 7.25 6.89706C6.83579 6.89706 6.5 7.23285 6.5 7.64706H8ZM5.5 6.89706C5.08579 6.89706 4.75 7.23285 4.75 7.64706C4.75 8.06127 5.08579 8.39706 5.5 8.39706V6.89706ZM19.5 8.39706C19.9142 8.39706 20.25 8.06127 20.25 7.64706C20.25 7.23285 19.9142 6.89706 19.5 6.89706V8.39706ZM14.375 7.64706C14.375 8.06127 14.7108 8.39706 15.125 8.39706C15.5392 8.39706 15.875 8.06127 15.875 7.64706H14.375ZM9.125 7.64706C9.125 8.06127 9.46079 8.39706 9.875 8.39706C10.2892 8.39706 10.625 8.06127 10.625 7.64706H9.125ZM17 7.64706V18.2353H18.5V7.64706H17ZM16 19.25H9V20.75H16V19.25ZM8 18.2353V7.64706H6.5V18.2353H8ZM9 19.25C8.45362 19.25 8 18.8016 8 18.2353H6.5C6.5 19.6182 7.61338 20.75 9 20.75V19.25ZM17 18.2353C17 18.8016 16.5464 19.25 16 19.25V20.75C17.3866 20.75 18.5 19.6182 18.5 18.2353H17ZM5.5 8.39706H19.5V6.89706H5.5V8.39706ZM15.875 7.64706V6.76471H14.375V7.64706H15.875ZM13.375 4.25H11.625V5.75H13.375V4.25ZM9.125 6.76471V7.64706H10.625V6.76471H9.125ZM11.625 4.25C10.2384 4.25 9.125 5.3818 9.125 6.76471H10.625C10.625 6.19837 11.0786 5.75 11.625 5.75V4.25ZM15.875 6.76471C15.875 5.3818 14.7616 4.25 13.375 4.25V5.75C13.9214 5.75 14.375 6.19837 14.375 6.76471H15.875Z" fill="currentColor"/>
</svg>
 Delete Product
                </span>
                <div
                  v-else
                  class="spinner-border spinner-border-sm"
                  role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            

            <div class="d-flex" role="group" aria-label="First group">
              
             
              <button v-if="isDiscard" @click.stop="$emit('discard')" class="duplicateProducts mx-2 d-flex align-items-center">
                <!--  -->
                <span v-if="!isLoadingDiscard">
                 
                  <svg width="20" class="mr-2" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.92515 12.4922C3.46551 14.0259 4.48971 15.3424 5.8434 16.2434C7.1971 17.1444 8.80697 17.5811 10.4304 17.4876C12.0539 17.3942 13.6031 16.7757 14.8444 15.7254C16.0858 14.675 16.9522 13.2497 17.313 11.6642C17.6738 10.0787 17.5096 8.41887 16.845 6.93483C16.1804 5.45078 15.0514 4.22291 13.6283 3.43622C12.2051 2.64952 10.5648 2.34663 8.95447 2.57318C6.23577 2.95566 4.43955 4.92621 2.5 6.66669M2.5 6.66669V1.66669M2.5 6.66669H7.5" stroke="currentColor" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
   Discard Changes
                </span>
                <div
                  v-else
                  class="spinner-border spinner-border-sm"
                  role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
              <Button @click.once="$emit('save')" :isLoading="isLoadingSave"> <svg width="20" class="mr-2" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.1667 7.5L8.33332 13.3333L5.83328 10.8333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
 Save Changes</Button>
              <!-- Delete Modal -->

            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import DeleteModal from '../modal/DeleteModal.vue';
import Button from './Button.vue';
export default {
  components:{
    Button,
    DeleteModal
  },
    props:{
        name:{
            type:String,
            default:'items'
        },
        isLoadingDelete:Boolean,
        isLoadingSave:Boolean,
        isLoadingDiscard:Boolean,
        isDelete:{
          type:Boolean,
          default:true
        },
        isDiscard:{
          type:Boolean,
          default:true
        }
    }
}
</script>
<style scoped>
.selected-count{
  display: flex;
    gap: 8px;
    align-items: center;
    color: #000;
align-items: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
}
.item-count{
  width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    border-radius: 8px;
    text-align: center;
    background-color: var(--secondary-color);
    color: white;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
.selectedItemsDev {
  position: fixed;
    top: auto;
    bottom: 0px;
    z-index: 120;
    left: 250px;
    width: calc(100% - 250px);
}
@media (max-width: 1024px) {
    .selectedItemsDev {
      width: calc(100% - 100px);
      left: 100px;
    }
}
@media (max-width: 557px) {
  .selectedItemsDev {
    width: calc(100%);
      left: 2px;
    }
}
.duplicateProducts {
  padding: 7px 12px;
display: flex;
gap: 4px;
border-radius: 6px;
background:var(--white);
border: 1px solid var(--border-color);
color: var(--grey-button);;
font-size: 13px;
font-style: normal;
font-weight: 600;
transition: 0.3s ease all
}
.duplicateProducts:hover{
  background: var(--grey-button);;
  color:var(--white);
}
.deleteProduct {
background:var(--white);
padding: 7px 12px;
display: flex;
gap: 4px;
border-radius: 6px;
border: 1px solid var(--delete-color);
color:var(--delete-color);
font-size: 13px;
font-style: normal;
font-weight: 600;
transition: 0.3s ease all
}
.deleteProduct:hover{
  background: var(--delete-color);
  color:var(--white);
}
.selectedItemsDev .InnerDiv {
  z-index: 999 !important;
  display: inline-flex;
padding: 12px 24px;
gap: var(--gap);
width:100%;
background:var(--white);
/* border-radius: var(--border-radius); */
border-top: 1px solid var(--border-color);



}

</style>